import { Cookies } from 'react-cookie-consent';
import { GLOBAL_COOKIE_NAME } from 'features/common/constants';
import { refresh } from '../../services/n2-auth';

const restartSession = async (refreshToken: string) => {
  const result = await refresh({
    refreshToken,
  });

  const body = await result.json();
  Cookies.set(GLOBAL_COOKIE_NAME, {
    token: body.accessToken,
    refreshToken: body.refreshToken,
  });
};

export const isAuthenticated = async () => {
  const cookie = Cookies.get(GLOBAL_COOKIE_NAME);
  const parsedCookie =
    cookie && typeof cookie === 'string' ? JSON.parse(cookie) : undefined;
  if (parsedCookie?.token) {
    return true;
  }

  if (parsedCookie?.refreshToken) {
    try {
      await restartSession(parsedCookie.refreshToken);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
  return false;
};
