/* eslint-disable react/jsx-no-useless-fragment */
import { MouseEvent } from 'react';
import {
  Nav,
  NavSection,
  NavItem,
  Briefcase,
  Chef,
  Currency,
  TeamSchedule,
  LogOut,
  Roster,
  Company,
  Block,
} from '@nowsta/ux-common-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppHeader } from 'features/common/components/AppHeader';
import { Cookies } from 'react-cookie-consent';

import { signOut } from 'services/n2-auth';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { routes, GLOBAL_COOKIE_NAME } from 'features/common/constants';

import { Contanier, Right, ChildrenHolder, NavWrapper } from './styled';

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { useNewInvoice } = useFlags();

  const handleLogout = async () => {
    const cookie = Cookies.get(GLOBAL_COOKIE_NAME);
    const parsedCookie =
      cookie && typeof cookie === 'string' ? JSON.parse(cookie) : undefined;
    await signOut({ refreshToken: parsedCookie.refreshToken });
    Cookies.remove(GLOBAL_COOKIE_NAME);
    navigate(routes.login);
  };

  const handleNavigate = (e: MouseEvent<HTMLButtonElement>) => {
    const { id: to } = e.currentTarget as HTMLButtonElement;

    navigate(to);
  };

  const currentRoute = location.pathname;

  return (
    <Contanier>
      <NavWrapper>
        <Nav>
          <NavSection>
            <NavItem
              active={currentRoute === routes.rates}
              id={routes.rates}
              label="Rate Sheet"
              onClick={handleNavigate}
              type="button"
              icon={<Currency />}
            />
            {useNewInvoice ? (
              <NavItem
                active={currentRoute === routes.invoices}
                id={routes.invoices}
                label="Invoices"
                onClick={handleNavigate}
                type="button"
                icon={<Briefcase />}
              />
            ) : (
              <></>
            )}
            <NavItem
              active={currentRoute === routes.invoicesHistory}
              id={routes.invoicesHistory}
              label="Invoice History"
              onClick={handleNavigate}
              type="button"
              icon={<TeamSchedule />}
            />
            {process.env.REACT_APP_PAYABLES_FEATURE &&
            process.env.REACT_APP_PAYABLES_FEATURE === 'on' ? (
              <NavItem
                active={currentRoute === routes.payments}
                id={routes.payments}
                label="Payables"
                onClick={handleNavigate}
                type="button"
                icon={<Roster />}
              />
            ) : (
              <></>
            )}
            <NavItem
              active={currentRoute === routes.positions}
              id={routes.positions}
              label="Positions"
              onClick={handleNavigate}
              type="button"
              icon={<Chef />}
            />
            <NavItem
              active={currentRoute === routes.agencies}
              id={routes.agencies}
              label="Agencies"
              onClick={handleNavigate}
              type="button"
              icon={<Company />}
            />
            {process.env.REACT_APP_ORDER_ALLOCATION_FEATURE &&
            process.env.REACT_APP_ORDER_ALLOCATION_FEATURE === 'on' ? (
              <NavItem
                active={currentRoute === routes.orderAllocation}
                id={routes.orderAllocation}
                label="Order Allocation"
                onClick={handleNavigate}
                type="button"
                icon={<Block />}
              />
            ) : (
              <></>
            )}
            <NavItem
              label="Log Out"
              type="button"
              icon={<LogOut />}
              onClick={handleLogout}
            />
          </NavSection>
        </Nav>
      </NavWrapper>
      <Right>
        <AppHeader />
        <ChildrenHolder>{children}</ChildrenHolder>
      </Right>
    </Contanier>
  );
};

export default Layout;
