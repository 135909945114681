import {
  RefreshTokenRequestBody,
  SignInRequestBody,
  ForgotPasswordRequestBody,
  ResetPasswordRequestBody,
} from '@nowsta/nowsta2-user-service';
import { Buffer } from 'buffer';

const API_URL = process.env.REACT_APP_N2_USER_SERVICE_URL ?? '';

const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const signIn = async ({ email, password }: SignInRequestBody) =>
  fetch(`${API_URL}/auth/signin/dra`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: baseHeaders,
  });

export const refresh = async ({ refreshToken }: RefreshTokenRequestBody) =>
  fetch(`${API_URL}/auth/refresh`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers: baseHeaders,
  });

export const signOut = async ({ refreshToken }: { refreshToken: string }) =>
  fetch(`${API_URL}/auth/signout`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers: baseHeaders,
  });

export const forgotPassword = async ({ email }: ForgotPasswordRequestBody) => {
  fetch(`${API_URL}/auth/password/forgot`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: baseHeaders,
  });
};

export const resetPassword = async ({
  email,
  verificationCode,
  password,
}: ResetPasswordRequestBody) => {
  fetch(`${API_URL}/auth/password/reset`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      verificationCode: verificationCode?.trim(),
      password,
    }),
    headers: baseHeaders,
  });
};

// eslint-disable-next-line class-methods-use-this
export const isTokenExpired = ({ accessToken }: { accessToken: string }) => {
  const parsedToken = JSON.parse(
    Buffer.from(accessToken.split('.')[1], 'base64').toString(),
  );
  const now = new Date().valueOf() / 1000;
  const expiry = parsedToken.exp || 0;

  return now > expiry;
};
