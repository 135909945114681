import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { signIn } from 'services/n2-auth';
import { GLOBAL_COOKIE_NAME, routes } from 'features/common/constants';
import { ArrowRight, Button, NowstaLogo } from '@nowsta/tempo-ds';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { useNavigate } from 'react-router-dom';
import { SignInFormState, SIGN_IN_SCHEMA } from './schema';
import * as Presenter from './CognitoLogin.presenter';

const CognitoLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<SignInFormState>({
    defaultValues: { email: '', password: '' },
    // @ts-ignore
    resolver: yupResolver(SIGN_IN_SCHEMA),
  });
  const { handleSubmit, control } = methods;

  const onSubmit: SubmitHandler<SignInFormState> = useCallback(async data => {
    try {
      setIsLoading(true);
      const result = await signIn({
        email: data.email,
        password: data.password,
      });
      switch (result.status) {
        case 200:
          break;
        case 400:
          setError(
            'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character',
          );
          return;
        case 401:
          setError('Unauthorized, please verify your credentials');
          return;
        case 403:
          setError('Insufficient permissions to log in');
          return;
        default:
          setError(
            'Something went wrong, please reach out to the development team for assistance',
          );
      }
      const body = await result.json();
      Cookies.set(GLOBAL_COOKIE_NAME, {
        token: body.accessToken,
        refreshToken: body.refreshToken,
      });
      setTimeout(() => {
        navigate(routes.rates);
      }, 600);
    } catch (err) {
      console.log(err);
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <Presenter.PageContainer>
        <Presenter.PageBanner>
          <NowstaLogo iconFill="white" />
          <span>Let&apos;s work</span>
        </Presenter.PageBanner>
        <Presenter.ContentBox>
          <Presenter.LogInContainer>
            <Presenter.LogInHeading>
              <h1>Welcome Back.</h1>
              <p>Enter your email and password to log-in.</p>
            </Presenter.LogInHeading>
            <FormProvider {...methods}>
              <Presenter.Form onSubmit={handleSubmit(onSubmit)}>
                <Presenter.FieldGroup>
                  <ControlledTextInput
                    control={control}
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="admin@nowsta.com"
                  />
                  <ControlledTextInput
                    control={control}
                    label="Password"
                    name="password"
                    type="password"
                    placeholder=""
                  />
                </Presenter.FieldGroup>
                <Presenter.FieldGroup>
                  {error && (
                    <Presenter.ErrorMessage>{error}</Presenter.ErrorMessage>
                  )}
                  <Button
                    type="submit"
                    uiSize="small"
                    iconRight={<ArrowRight />}
                    sizeUI={{ w: '100%' }}
                    disabled={isLoading}
                    isWorking={isLoading}
                  >
                    Log in
                  </Button>
                </Presenter.FieldGroup>
              </Presenter.Form>
            </FormProvider>
            <Presenter.ResetPassword>
              Forgot password? Reset it{' '}
              <a
                href={`${process.env.REACT_APP_WEB_APP_URL}/users/password/new`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Reset your password here"
              >
                here
              </a>
              .
            </Presenter.ResetPassword>
          </Presenter.LogInContainer>
        </Presenter.ContentBox>
      </Presenter.PageContainer>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName={GLOBAL_COOKIE_NAME}
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        Nowsta uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
};

export default CognitoLogin;
